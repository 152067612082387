
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement, ApiBase, ApiBrands, ApiCampaign } from "@/core/api";
import ShowContentModel from "@/views/task-management/standard-operating-procedure/ShowContentModel.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import moment from "moment";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

interface TaggingItem {
  label: string;
  value: string;
}

export default defineComponent({
  name: "quick-inquiry-information",
  components: {
    ShowContentModel,
    PermissionCommon,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const closeButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      task_owner: "",
      request: "",
      requested_by: "",
      request_date: "",
      due_date: "",
      quote_date: "",
      quote: "",
      quote_amount: "",
      related_products: [],
      importance: "",
      internal_comment: "",
      comment_tagging: [],
      close_status: "",
      close_date: "",
      task_source: "",
      task_source_data_id: "",
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const rules = ref({
      task_owner: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      request: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      requested_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      request_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      due_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      importance: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === "" || value === 0) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      quote_date: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                formData.value.request_date &&
                value &&
                moment(value).valueOf() <=
                  moment(formData.value.request_date).valueOf()
              ) {
                reject(t("quickInquiry.plsQuoteDate"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const options = ref({
      sop: [],
      standard_script: [],
    });

    const showTitle = ref<string>("");
    const showContent = ref<string>("");

    const taskOwnerOptions = ref([]);
    const taskOwnerLoading = ref<boolean>(false);
    const requestedByOptions = ref<Array<TaggingItem>>([]);
    const requestedByLoading = ref<boolean>(false);
    const relatedProductsOptions = ref([]);
    const relatedProductsLoading = ref<boolean>(false);
    const commentTaggingOptions = ref([]);
    const commentTaggingLoading = ref<boolean>(false);
    const campaignOptions = ref([]);
    const closeStatusOptions = ref([]);
    const closeStatusLoading = ref<boolean>(true);
    const closeLoading = ref<boolean>(false);
    // SOP and Script
    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([]);
    const typeLoading = ref<boolean>(false);
    const applyToOptions = ref([]);
    const applyToLoading = ref<boolean>(false);
    const suitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);

    const getSopStandardScriptInfo = async () => {
      const { data } = await ApiTaskManagement.getSopStandardScriptInfo({
        id: route.params.id,
        type: "inquiry",
      });
      if (data.code == 0) {
        options.value.sop = data.data.sop;
        options.value.standard_script = data.data.standard_script;
      }
    };

    const getCampaignsSourceData = async () => {
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        campaignOptions.value = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "call_out_subject",
          "quick_inquiry_comment_tagging",
          "quick_inquiry_close_status",
          "sop_apply_to",
        ],
      });

      if (data.code == 0) {
        subjectOptions.value = data.data.call_out_subject.items;
        commentTaggingOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        closeStatusOptions.value = data.data.quick_inquiry_close_status.items;
        applyToOptions.value = data.data.sop_apply_to.items;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (taskOwnerLoading.value = true)
            : (taskOwnerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          taskOwnerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData({
        ...params,
        status: 0,
      });
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchTaskOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (requestedByLoading.value = true)
            : (requestedByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          requestedByOptions.value = data;
          setDocTitle();
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchRequestedByItems = (query) => {
      debounceAccountSearch(0, query);
    };

    const switchPimType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (relatedProductsLoading.value = true)
            : (relatedProductsLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchPimValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          relatedProductsOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getPimSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchPimType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBrands.getBrandSourceData(params);
      switchPimType(type, false);
      if (data.code == 0) {
        switchPimValue(type, data.data);
      }
    };

    const debouncePimSearch = _.debounce(getPimSourceData, 1000);
    const searchRelatedProductsItems = (query) => {
      debouncePimSearch(0, query);
    };

    const getQuickInquiryInfo = async (type?: number) => {
      loading.value = true;
      const { data } = await ApiTaskManagement.getQuickInquiryInfo({
        id: route.params.id,
      });
      loading.value = false;
      closeStatusLoading.value = false;
      if (data.code == 0) {
        if (type == 1) {
          formData.value.close_date = data.data.close_date;
        } else {
          formData.value = data.data;
          if (data.data.related_products != "") {
            getPimSourceData(0, "", data.data.related_products);
          }
          if (data.data.task_owner != "") {
            getUserSourceData(0, "", data.data.task_owner);
          }
          if (data.data.requested_by != "") {
            getAccountSourceData(0, "", data.data.requested_by);
          }
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiTaskManagement.updateQuickInquiry(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteQuickInquiry = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Quick Inquiry?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiTaskManagement.deleteQuickInquiry({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Quick Inquiry has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "quick-inquiry" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const handleCloseStatus = async () => {
      closeLoading.value = true;
      const { data } = await ApiTaskManagement.closeQuickInquiry({
        id: route.params.id,
        close_status: formData.value.close_status,
      });
      closeLoading.value = false;
      if (data.code == 0) {
        getQuickInquiryInfo(1);
      }
    };

    const showContentModel = (title: string, content: string) => {
      showTitle.value = title;
      showContent.value = content;
    };

    const setDocTitle = () => {
      let obj = requestedByOptions.value.find((item) => {
        return item.value == formData.value.requested_by;
      });
      document.title =
        (obj?.label as string) + " - MagicBean SaaS Distribution";
    };

    onBeforeMount(() => {
      getQuickInquiryInfo();
      getDropdownOptions();
      getSopStandardScriptInfo();
      getCampaignsSourceData();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      options,
      formRef,
      submitButton,
      deleteButton,
      taskOwnerOptions,
      taskOwnerLoading,
      campaignOptions,
      requestedByOptions,
      requestedByLoading,
      relatedProductsOptions,
      relatedProductsLoading,
      commentTaggingOptions,
      commentTaggingLoading,
      closeStatusOptions,
      closeStatusLoading,
      closeLoading,
      // SOP and Script
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      applyToOptions,
      applyToLoading,
      suitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      showTitle,
      showContent,
      moment,
      t,
      searchTaskOwnerItems,
      searchRequestedByItems,
      searchRelatedProductsItems,
      submit,
      deleteQuickInquiry,
      handleCloseStatus,
      showContentModel,
    };
  },
});
